<template>
  <div class="downLoad-outing">
    <div class="downLoad-box">
      <div class="downLoad-imgbox">
        <img :src="fileTypeImg()" alt="">
      </div>
    </div>
    <div class="downLoad-text">{{fileDownLoad.fileName}}</div>
    <div class="bottom-postion">
      <a :href="fileDownLoad.fileUrl" :download="fileDownLoad.filrUrl" class="btn downLoad-btn">
        <cube-button>下载</cube-button>
      </a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'FileDownLoad',
  data() {
    return {
      fileTypeImgObj: {
        'doc': require('../../assets/img/img_doc@3x.png'),
        'pdf': require('../../assets/img/img_pdf@3x.png'),
        'zip': require('../../assets/img/img_zip@3x.png'),
        'else': require('../../assets/img/img_else@3x.png')
      }
    };
  },
  computed: {
    ...mapGetters('workreport', ['fileDownLoad'])
  },
  methods: {
    fileTypeImg() {
      if (this.fileDownLoad.fileUrl) {
        let type = this.fileDownLoad.fileUrl.match(/([^.]+)$/)[1];
        console.log(type);
        for (let i in this.fileTypeImgObj) {
          if (type === i) {
            return this.fileTypeImgObj[i];
          }
        }
        return this.fileTypeImgObj['else'];
      }
    }
  },
  mounted() {
    console.log(this.fileDownLoad);
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped>
  .downLoad-outing{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $color-FFF;
    z-index: 599;
  }
  .downLoad-box {
    display: flex;
    justify-content:center;
    height:160px;
    margin-bottom: 40px;
    overflow: hidden;
    .downLoad-imgbox{
      width: 60px;
      height: 60px;
      margin-top: 100px;
      img{
        width: 60px;
      }
    }
  }
  .downLoad-text{
    color: $color-333;
    font-size: $font-16;
    line-height: 22px;
    font-weight: 600;
    margin: 0 15px;
  }
  .downLoad-btn{
    width:100%;
  }
</style>
